<template>
  <div class="mod-freezer">
    <amis-renderer :schema="schema" :on-action="onAction" />
  </div>
</template>

<script>
import AmisRenderer from '@/components/AmisRenderer';
import { excelExport } from '@/utils';
import moment from 'moment';
import schema from './schemas/freezer.json';

export default {
  name: 'Freezer',
  components: { AmisRenderer },
  data() {
    return {
      schema,
    };
  },
  methods: {
    // 按钮动作回调
    async onAction(e, action, ctx) {
      if (action.actionType === 'export-group') {
        const filename = `团餐人员信息 - ${moment().format('YYYYMMDDHHmmss')}`;
        const columns = [
          { label: '手机号', value: (item) => item.mobile },
          { label: '姓名', value: (item) => item.name },
          { label: '备注', value: (item) => item.remark },
        ];
        const items = ctx.userInfo;
        await excelExport(filename, columns, items);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
